import {defineComponent, onMounted, ref, watch} from "vue";
import { IonPage, IonRippleEffect } from "@ionic/vue";
import { useRouter } from "vue-router";
import {
    savePreferences,
    Preferences
} from "@/storage/DataStorage";
import translate from "../translate";
import {useStore} from "vuex";
import {checkIsMobileDevice, checkStillLocked, setCalcLock} from "@/views/Calculator/LockingCommon";
import {openAlert, showEmergencyAlertAndNavigate} from "@/views/Calculator/CalculatorCommon";
import {checkForJourneySetImport} from "@/utilities/JourneySet";
import {deleteApp} from "@/utilities/Filesystem";
import {DeleteApp} from '@/delete-app-plugin/definitions';

export default defineComponent({
    name: "Calculator",
    components: {
        IonPage,
        IonRippleEffect,
    },
    async ionViewWillEnter() {
        this.operatorClicked = false;
        this.shouldAuthenticate = true;
        this.display = "0";
        await this.store.dispatch("setNeedToSetPin", true);
        this.preferences = this.store.getters.getPreferences();
        if (!this.store.getters.isFirstTimeOpeningApp() && this.preferences.shouldSetPin) {
            await openAlert(
                `<p>${this.t("messages.calculator.appSecuredMessage")}</p> <p>${this.t("messages.calculator.createPINMessage")}</p>`,
                `${this.t("messages.calculator.next")}`
            );
        } else {
            this.operatorClasses = ["ion-activatable", "ripple-parent"];
        }
    },
    setup() {
        const {t} = translate();
        const store = useStore();
        const preferences = ref({} as Preferences);
        let previous = "";
        const display = ref("0");
        const operatorClasses = ref([] as string[]);
        let operator: any = null;
        const operatorClicked = ref(false);
        const stillLocked = ref(false);
        const isMobileDevice = ref(false);
        let calculationCompleted = true;
        
        /* Pin has to be entered from a cleared state. Either when the calc first opens or by hitting clear 
           until "0" appears when not in the middle of an operation.
           Those are the only times that the pin should be evaluated. */
        const shouldAuthenticate = ref(true);
        
        const deleteOne = () => {
            if (display.value) {
                display.value = display.value.toString().slice(0, -1);
                if (display.value.toString().length === 0) {
                    display.value = "0";
                    shouldAuthenticate.value = calculationCompleted;
                }
            }
        };

        onMounted(async () => {
            isMobileDevice.value = await checkIsMobileDevice();
        });

        const translateNumber = (currentNumber: string) => {
            const messageObject = {
                "0": "messages.calculator.zero",
                "1": "messages.calculator.one",
                "2": "messages.calculator.two",
                "3": "messages.calculator.three",
                "4": "messages.calculator.four",
                "5": "messages.calculator.five",
                "6": "messages.calculator.six",
                "7": "messages.calculator.seven",
                "8": "messages.calculator.eight",
                "9": "messages.calculator.nine",
            } as any;
            return messageObject[currentNumber];
        };

        const translatedDisplay = ref("0");
        watch(display, (display) => {
            let result = "";
            display
                .toString()
                .split("")
                .forEach((currentNumber) => {
                    const translatedLetter = translateNumber(currentNumber);
                    // terinary is in place to handle decimals since they are universal...
                    result += translatedLetter ? t(translateNumber(currentNumber)) : currentNumber;
                });
            translatedDisplay.value = result;
        });
        
        const append = (number: string) => {
            if (operatorClicked.value) {
                display.value = "";
                operatorClicked.value = false;
            }
            display.value = display.value === "0" ? (display.value = number) : "" + display.value + number;
        };
        const decimal = () => {
            if (display.value.indexOf(".") === -1) {
                append(".");
            }
        };

        const operatorClickedActions = () => {
            previous = display.value;
            operatorClicked.value = true;
            shouldAuthenticate.value = false;
            calculationCompleted = false;
        }
        
        const divide = () => {
            operator = (a: number, b: number) => a / b;
            operatorClickedActions();
        };
        const multiply = () => {
            operator = (a: number, b: number) => a * b;
            operatorClickedActions();
        };
        const subtract = () => {
            operator = (a: number, b: number) => a - b;
            operatorClickedActions();
        };
        const add = () => {
            operator = (a: number, b: number) => a + b;
            operatorClickedActions();
        };
        const router = useRouter();
        const cleanUp = () => {
            previous = "";
            operatorClicked.value = true;
            operator = null;
        };

        const setCalculatorPin = async (preferences: Preferences) => {
            if (display.value && display.value.length === 4 && !previous) {
                previous = display.value;
                display.value = "";
                await openAlert(`<p>${t("messages.calculator.reenterPinMessage")}</p>`, `${t("messages.calculator.next")}`);
            } else if (previous && previous === display.value) {
                preferences.entrancePin = display.value;
                preferences.shouldSetPin = false;
                await savePreferences(preferences);
                display.value = "";
                cleanUp();
                await openAlert(
                    `<p>${t("messages.calculator.setPINMessage")}</p> <p>${t("messages.calculator.usePINMessage")}</p>`,
                    `${t("messages.calculator.next")}`,
                    () => showEmergencyAlertAndNavigate(preferences.lastVisitedRoute, "messages.calculator.emergencyPINToUseMessage", t)
                );
            } else if (previous && previous !== display.value) {
                display.value = "";
                cleanUp();
                await openAlert(
                    `<p>${t("messages.calculator.failedPinResetMessage")}</p> <p>${t("messages.calculator.tryAgainMessage")}</p>`,
                    `${t("messages.calculator.next")}`
                );
            } else {
                display.value = "";
                cleanUp();
                await openAlert(
                    `<p>${t("messages.calculator.failedPINMessage")}</p> <p>${t("messages.calculator.tryAgainMessage")}</p>`,
                    `${t("messages.calculator.next")}`
                );
            }
        };

        const evaluate = async () => {
            if (isMobileDevice.value) {
                stillLocked.value = await checkStillLocked(preferences.value);
            }

            if (display.value.toString() === preferences.value.deletePin) {
                display.value = "0";
                cleanUp();
                if (isMobileDevice.value) {
                    deleteApp();
                } else {
                    DeleteApp.deleteApp();
                }
            } else if (shouldAuthenticate.value && !stillLocked.value && display.value.toString() === preferences.value.entrancePin && preferences.value.canAccessApp) {
                await store.dispatch("clearLockoutDateTime");
                await store.dispatch("clearFailedAttempts");
                await store.dispatch("setNeedToSetPin", false);
                await router.push({path: `${preferences.value.lastVisitedRoute}`});
                await checkForJourneySetImport();
                display.value = "0";
                cleanUp();
            } else if (operator) {
                display.value = operator(Number(previous), Number(display.value));
                cleanUp();
                calculationCompleted = true;
                shouldAuthenticate.value = display.value.toString() === "0";
            } else if (shouldAuthenticate.value && isMobileDevice.value && !stillLocked.value) {
                await store.dispatch("incrementFailedAttempts");
                const failedAttempts = store.getters.getFailedAttempts();
                if (failedAttempts === 5) {
                    await store.dispatch("setLockoutDateTime");
                    await setCalcLock(preferences.value);
                } else if (failedAttempts === 20) {
                    await setCalcLock(preferences.value);
                }
            }
        };
        const equal = async () => {
            try {
                if (preferences.value.shouldSetPin) {
                    await setCalculatorPin(preferences.value);
                } else {
                    await evaluate();
                }
            } catch {
                if (operator) {
                    display.value = operator(Number(previous), Number(display.value));
                    cleanUp();
                }
            }
        };

        return {
            deleteOne,
            append,
            decimal,
            divide,
            multiply,
            subtract,
            add,
            equal,
            display,
            t,
            translatedDisplay,
            operatorClasses,
            store,
            preferences,
            stillLocked,
            operatorClicked,
            shouldAuthenticate
        };
    },
});
